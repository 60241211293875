import { useState, useEffect } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import AdminApi from 'src/lib/adminApi';

import { useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

import AdminRegistrationsDetailResponse from '../../../../../shared/types/serverResponses/AdminRegistrationsDetailResponse';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RegistrationUrl } from 'src/shared/types/RegistrationUrl';
import { InputType } from 'src/shared/types/InputType';
import { RegistrationType } from 'src/shared/types/RegistrationType';

import { REGISTRATION_TYPES } from 'src/shared/enums/registrationTypes';
import normalizeString from 'src/lib/normalizeString';
import RegistrationInput from 'src/shared/types/RegistrationInput';

type RegistrationEditFormValues = {
    registrationName: string;
    webinarId: string;
    hexColor: string;

    htmlBody: string;
    textBody: string;
    dataProtectionHtml: string;
    confirmationEmailHtml: string;

    isStopped: number;
    isArchived: number;

    bannerUrl: string;
    backgroundUrl: string;

    emailProviderId: string;

    capacity_customers: number;
    capacity_partners: number;
    capacity_dellteam: number;
    capacity_swsteam: number;
    capacity_100mega: number;
    capacity_atcomputers: number;

    icalWhere: string | null;
    icalWho: string | null;

    hasRegistrationTypesRadio: number;
    sendIcalWithConfirmation: number;
}

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
];

const modules = {
    toolbar: {
        container: toolbarOptions,
    },
    imageUploader: {
        upload: async file => {
            const formData = new FormData();
            formData.append("file", file);
            const adminToken = localStorage.getItem('admin-token') as string;
            const url = await AdminApi.uploadFile(adminToken, formData);
            return url;
        }
    }
}

export default function RegistrationsEdit() {
    const adminToken = useAdminTokenCheck();
    const { id } = useParams();
    const history = useHistory();

    const [temporaryUrl, setTemporaryUrl] = useState("");

    const [registrationsDetail, setRegistrationsDetail] = useState<AdminRegistrationsDetailResponse | null>(null);
    const [formValues, setFormValues] = useState<RegistrationEditFormValues>({
        registrationName: "",
        webinarId: "",
        htmlBody: "",
        dataProtectionHtml: "",
        confirmationEmailHtml: "",
        textBody: "",
        isStopped: 0,
        isArchived: 0,
        bannerUrl: "",
        backgroundUrl: "",
        hexColor: "",
        emailProviderId: "",
        capacity_customers: 0,
        capacity_partners: 0,
        capacity_dellteam: 0,
        capacity_swsteam: 0,
        capacity_100mega: 0,
        capacity_atcomputers: 0,
        icalWhere: null,
        icalWho: null,
        hasRegistrationTypesRadio: 1,
        sendIcalWithConfirmation: 1,
    });
    const [allLoaded, setAllLoaded] = useState<boolean>(false);

    const [inputTypes, setInputTypes] = useState<InputType[]>([]);
    const [registrationType, setRegistrationType] = useState<RegistrationType[]>([]);
    const [inputLabel, setInputLabel] = useState("");
    const [inputType, setInputType] = useState("1");
    const [inputRegistrationType, setInputRegistrationType] = useState<any[]>([]);

    const [selectedBackground, setSelectedBackground] = useState("");
    const [selectedBanner, setSelectedBanner] = useState("");

    const loadData = async () => {
        const inputTypesData = await AdminApi.getInputTypes(adminToken);
        const data = await AdminApi.getRegistrationsDetail(adminToken, id);
        const registrationTypesData = await AdminApi.getRegistrationTypes(adminToken);

        setRegistrationType(registrationTypesData);
        setInputTypes(inputTypesData);
        setRegistrationsDetail(data);
        setFormValues({
            ...formValues,
            ...data.registrationPage,
            registrationName: data.registrationPage.registrationName,
            webinarId: data.registrationPage.webinarId.toString(),
            htmlBody: data.registrationPage.htmlBody ? data.registrationPage.htmlBody : "",
            textBody: data.registrationPage.textBody ? data.registrationPage.textBody : "",
            dataProtectionHtml: data.registrationPage.dataProtectionHtml ? data.registrationPage.dataProtectionHtml : "",
            confirmationEmailHtml: data.registrationPage.confirmationEmailHtml ? data.registrationPage.confirmationEmailHtml : "",
            bannerUrl: data.registrationPage.bannerUrl ? data.registrationPage.bannerUrl : "",
            backgroundUrl: data.registrationPage.backgroundUrl ? data.registrationPage.backgroundUrl : "",
            hexColor: data.registrationPage.hexColor,
            emailProviderId: data.registrationPage.emailProviderId as string,
            capacity_customers: data.registrationPage.capacity_customers ? data.registrationPage.capacity_customers : 0,
            capacity_partners: data.registrationPage.capacity_partners ? data.registrationPage.capacity_partners : 0,
            capacity_dellteam: data.registrationPage.capacity_dellteam ? data.registrationPage.capacity_dellteam : 0,
            capacity_swsteam: data.registrationPage.capacity_swsteam ? data.registrationPage.capacity_swsteam : 0,
            capacity_100mega: data.registrationPage.capacity_100mega ? data.registrationPage.capacity_100mega : 0,
            capacity_atcomputers: data.registrationPage.capacity_atcomputers ? data.registrationPage.capacity_atcomputers : 0,
            icalWhere: data.registrationPage.icalWhere ? data.registrationPage.icalWhere : null,
            icalWho: data.registrationPage.icalWho ? data.registrationPage.icalWho : null,
            hasRegistrationTypesRadio: data.registrationPage.hasRegistrationTypesRadio,
            sendIcalWithConfirmation: data.registrationPage.sendIcalWithConfirmation ? 1 : 0,
        });

        document.title = `Úprava - ${data.registrationPage.registrationName}`;
        setAllLoaded(true);
    }

    useEffect(() => {
        loadData();
    }, []);

    const onChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const handleMultipleInputChange = (e) => {
        const arr: any[] = []
        for (const option of e.target.selectedOptions) {
            arr.push(option.value)
        }

        setInputRegistrationType(arr);
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const editRegistration = async () => {
        const registrationToSend = formValues;

        if (!formValues.icalWhere) {
            registrationToSend["icalWhere"] = null;
        }

        if (!formValues.icalWho) {
            registrationToSend["icalWho"] = null;
        }

        // Set the values to zero if they are not present
        if (!formValues.capacity_100mega) {
            registrationToSend["capacity_100mega"] = 0;
        }

        if (!formValues.capacity_atcomputers) {
            registrationToSend["capacity_atcomputers"] = 0;
        }

        if (!formValues.capacity_swsteam) {
            registrationToSend["capacity_swsteam"] = 0;
        }

        if (!formValues.capacity_customers) {
            registrationToSend["capacity_customers"] = 0;
        }

        if (!formValues.capacity_partners) {
            registrationToSend["capacity_partners"] = 0;
        }

        if (!formValues.capacity_dellteam) {
            registrationToSend["capacity_dellteam"] = 0;
        }

        if (selectedBanner) {
            const formData = new FormData();
            formData.append("file", selectedBanner);
            let url = await AdminApi.uploadFile(adminToken, formData);
            registrationToSend["bannerUrl"] = url;
        }

        if (selectedBackground) {
            const formData = new FormData();
            formData.append("file", selectedBackground);
            let url = await AdminApi.uploadFile(adminToken, formData);
            registrationToSend["backgroundUrl"] = url;
        }

        const isOk = await AdminApi.editRegistration(adminToken, registrationsDetail?.registrationPage.id, registrationToSend);

        if (isOk) {
            history.push(`/admin/registrations/detail/${registrationsDetail?.registrationPage.id}`);
        }
    }

    const htmlBodyChange = (htmlBody) => {
        setFormValues({ ...formValues, htmlBody })
    }

    const dataProtectionHtmlChange = (dataProtectionHtml) => {
        setFormValues({ ...formValues, dataProtectionHtml })
    }

    const confirmationEmailHtmlChange = (confirmationEmailHtml) => {
        setFormValues({ ...formValues, confirmationEmailHtml })
    }

    const deleteUrl = async (idToDelete) => {
        if (!registrationsDetail) return;

        if (!window.confirm("Opravdu chcete smazat URL adresu?")) return;

        const newUrls: RegistrationUrl[] = registrationsDetail.urls.filter((url, index) => url.id !== idToDelete);
        setRegistrationsDetail({ ...registrationsDetail, urls: newUrls });
        const result = await AdminApi.deleteRegistrationUrl(adminToken, idToDelete);
        console.log(result);

    }

    const addUrl = async () => {
        if (!registrationsDetail) return;

        try {
            const result = await AdminApi.addRegistrationUrl(adminToken, registrationsDetail.registrationPage.id, temporaryUrl);
            setRegistrationsDetail({
                ...registrationsDetail,
                urls: [
                    ...registrationsDetail.urls,
                    {
                        registrationId: registrationsDetail.registrationPage.id,
                        url: temporaryUrl,
                        id: result.urlId
                    }
                ]
            })
            setTemporaryUrl("");

        }
        catch (e) {
            window.alert("Nepodařilo se přidat url. Buď je ve špatném formátu, nebo již existuje.");
        }
    }

    const deleteInput = async (idToDelete) => {
        if (!registrationsDetail) return;
        if (!window.confirm("Opravdu chcete tento vstup odstranit? Smažete tím i veškeré odpovědi od uživatelů.")) return;

        const parsedId = parseInt(idToDelete);

        if (!parsedId) return;

        console.log("deleting", parsedId);
        await AdminApi.deleteRegistrationInput(adminToken, parsedId);

        const newInputs = registrationsDetail.inputs.filter((value) => value.id !== parsedId);
        setRegistrationsDetail({ ...registrationsDetail, inputs: newInputs });
    }

    const addInput = async () => {
        const parsedInputType = parseInt(inputType);

        if (!window.confirm("Opravdu chcete tyto vstupy přidat?")) return;

        //console.log("Adding", registrationsDetail, parsedInputType, inputType);

        if (!registrationsDetail || !parsedInputType) return;

        const inputsToAdd: RegistrationInput[] = [];

        for (const type of inputRegistrationType) {
            const inputToAdd = {
                registrationId: registrationsDetail.registrationPage.id,
                registrationTypeId: parseInt(type),
                registrationInputTypeId: parsedInputType,
                inputName: normalizeString(inputLabel).substring(0, 600),
                inputLabel: inputLabel,
                capacity: 9999,
            }
            const { inputId } = await AdminApi.addRegistrationInput(adminToken, inputToAdd);
            inputsToAdd.push({ ...inputToAdd, id: inputId });

        }

        setRegistrationsDetail({
            ...registrationsDetail,
            inputs: [
                ...registrationsDetail.inputs, ...inputsToAdd
            ],
        });

        console.log([...registrationsDetail.inputs, ...inputsToAdd]);
    }

    const backgroundFileChange = (e) => {
        setSelectedBackground(e.target.files[0]);
    }

    const bannerFileChange = (e) => {
        setSelectedBanner(e.target.files[0]);
    }

    const toggleRegistrationTypesRadio = (e) => {
        const { checked } = e.target;
        setFormValues({ ...formValues, hasRegistrationTypesRadio: checked ? 1 : 0 })
    }

    const toggleSendIcalWithConfirmation = (e) => {
        const { checked } = e.target;
        setFormValues({ ...formValues, sendIcalWithConfirmation: checked ? 1 : 0 })
    }

    if (!allLoaded) return null;

    return <div className="row">
        <h1>Edit - {registrationsDetail?.registrationPage.registrationName}</h1>
        <p className="mt-2 w-100">Pro webinář {registrationsDetail?.registrationPage.webinarId} - {registrationsDetail?.registrationPage.webinarName}</p>
        <form className="col-12 pt-3" onSubmit={onSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <h2>Informace o registraci</h2>
                    <label>Název registrace</label>
                    <input type="text" name="registrationName" className="form-control" value={formValues.registrationName} onChange={onChange} placeholder="Název registrace"></input>
                    <label>Pozadí - <a href={formValues.backgroundUrl} target="BLANK">Výchozí</a></label>
                    <input className="form-control mb-3" type="file" name="file" onChange={backgroundFileChange}></input>
                    <label>Logo - <a href={formValues.bannerUrl} target="BLANK">Výchozí</a></label>
                    <input className="form-control mb-3" type="file" name="file" onChange={bannerFileChange}></input>
                    <label>Barva registrace - Výchozí: {formValues.hexColor}</label>
                    <input className="form-control form-control-color mb-3" type="color" id="hexColor" name="hexColor" onChange={onChange} value={formValues.hexColor}></input>
                    <label>Email přijde od:</label>
                    <select className="form-control" name="emailProviderId" id="emailProviderId" onChange={onChange} value={formValues.emailProviderId} defaultValue={formValues.emailProviderId}>
                        <option className="option" value={1}>
                            Dell Technologies - events@dellnews.cz
                        </option>
                        <option className="option" value={2}>
                            AgenturaXact - damu@jakub.dev
                        </option>
                        <option className="option" value={3}>
                            SWS - sws@agenturaxact.cz
                        </option>
                        <option className="option" value={4}>
                            100MEGA - events@dellnews.cz
                        </option>
                        <option className="option" value={5}>
                            DNS - altepro@xact.cz
                        </option>
                    </select>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Zákazník 100MEGA Distribution" : "Zákazníci"}</label>
                    <input type="number" name="capacity_customers" className="form-control" placeholder="Kapacita zákazníci" value={formValues.capacity_customers} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Partneři 100MEGA Distribution" : "Partneři"}</label>
                    <input type="number" name="capacity_partners" className="form-control" placeholder="Kapacita partneři" value={formValues.capacity_partners} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Tým 100MEGA Distribution" : "DellTeam"}</label>
                    <input type="number" name="capacity_dellteam" className="form-control" placeholder="Kapacita Dell" value={formValues.capacity_dellteam} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Zákazník Dell Technologies" : "SWSTeam"}</label>
                    <input type="number" name="capacity_swsteam" className="form-control" placeholder="Kapacita SWS" value={formValues.capacity_swsteam} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Partneři Dell Technologies" : "100MEGATeam"}</label>
                    <input type="number" name="capacity_100mega" className="form-control" placeholder="Kapacita 100MEGA" value={formValues.capacity_100mega} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie {registrationsDetail?.registrationPage.id == 169 ? "Tým Dell Technologies" : "AT Computers"}</label>
                    <input type="number" name="capacity_atcomputers" className="form-control" placeholder="Kapacita AT Computers" value={formValues.capacity_atcomputers} onChange={onChange}></input>

                    <div className="form-check my-4">
                        <input type="checkbox" className="form-check-input" onClick={toggleRegistrationTypesRadio} checked={!!formValues.hasRegistrationTypesRadio} />
                        <label className="form-check-label">Zapnout kategorie registrací (zákazníci, partneři, dell...)</label>
                    </div>

                    <h2 className="pt-3">Texty registrace</h2>
                    <label className="mt-2">Kalendář - pořadatel (kdo) - pokud je prázdné, bude nastaveno dle vybraného emailu</label>
                    <input type="text" name="icalWho" className="form-control" placeholder="Kalendář - kdo" value={formValues.icalWho ? formValues.icalWho : ""} onChange={onChange}></input>
                    <label className="mt-2">Kalendář - kde - pokud je prázdné, bude odkaz na webinář</label>
                    <input type="text" name="icalWhere" className="form-control" placeholder="Kalendář - kde" value={formValues.icalWhere ? formValues.icalWhere : ""} onChange={onChange}></input>
                    <label className="mt-2">Text registrace (na registrační stránce)</label>
                    <ReactQuill
                        defaultValue={formValues.htmlBody}
                        modules={modules}
                        onChange={htmlBodyChange}
                        value={formValues.htmlBody}
                    />
                    <label className="mt-2">Ochrana osobních údajů</label>
                    <ReactQuill
                        defaultValue={formValues.dataProtectionHtml}
                        modules={modules}
                        onChange={dataProtectionHtmlChange}
                        value={formValues.dataProtectionHtml}
                    />
                    <label className="mt-2">Potvrzovací email</label>
                    <ReactQuill
                        defaultValue={formValues.confirmationEmailHtml}
                        modules={modules}
                        onChange={confirmationEmailHtmlChange}
                        value={formValues.confirmationEmailHtml}
                    />
                    <p>Proměnná <strong>{"{{userInputs}}"}</strong> pro vložení vstupů uživatele do emailu.</p>
                    <label className="mt-2">Popisek do ICS - pouze text - proměnná {"{{url}}"}</label>
                    <textarea onChange={onChange} name="textBody" className="form-control">{registrationsDetail?.registrationPage.textBody}</textarea>

                    <div className="form-check my-4">
                        <input type="checkbox" className="form-check-input" onClick={toggleSendIcalWithConfirmation} checked={!!formValues.sendIcalWithConfirmation} />
                        <label className="form-check-label">Odeslat ics soubor s potvrzovacím emailem?</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <h2 className="pt-3">URL adresy</h2>
                    <label>Zadané adresy:</label>
                    {registrationsDetail?.urls.map((url: RegistrationUrl, index: number) => {
                        return <div key={index}>
                            <button className="mr-3 my-2 btn btn-danger" onClick={() => deleteUrl(url.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <span>{url.id} - <strong><Link target="_blank" to={`/r/${url.url}`}>{url.url}</Link></strong></span>
                        </div>
                    })}
                    <div className="row no-gutters">
                        <label className="col-12">Url</label>
                        <input type="text" name="temporaryUrl" className="form-control px-3 col-10" placeholder="Url pod kterou bude registrace dostupná" value={temporaryUrl} onChange={(e) => setTemporaryUrl(e.target.value)}></input>
                        <button className="btn btn-success col-1 ml-5" onClick={addUrl}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <h2 className="py-3">Vstupy</h2>
                    {registrationsDetail?.inputs.map((input, index) => {
                        return <div key={index} className="row pb-3">
                            <div className="col-1">
                                <button className="mr-3 btn btn-danger my-3" onClick={() => deleteInput(input.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                            <div className="col-11">
                                <div className="d-inline-block">
                                    <p style={{ wordBreak: "break-word" }}>
                                        <strong>Název vstupu:</strong> {input.inputLabel}<br /> <strong>V reportu:</strong> {input.inputName}<br /> <strong>Typ registrace:</strong> {REGISTRATION_TYPES[input.registrationTypeId]}
                                    </p>
                                </div>
                            </div>
                        </div>
                    })}
                    <h2 className="py-3">Přidat vstup</h2>
                    <label className="mt-2">Popisek vstupu na stránce s registrací</label>
                    <input type="text" name="inputLabel" className="form-control" value={inputLabel} onChange={(e) => setInputLabel(e.target.value)} placeholder="Popisek vstupu"></input>

                    <label className="mt-2">Typ vstupu</label>
                    <select name="inputType" className="form-control" onChange={(e) => setInputType(e.target.value)} defaultValue="1">
                        {inputTypes.map((type: InputType, index: number) => {
                            return <option defaultChecked={index === 0} key={index} value={type.id}>{type.inputType}</option>
                        })}
                    </select>

                    <label className="mt-2">Bude pro typ registrace:</label>
                    <select name="registrationType" multiple className="form-control" onChange={handleMultipleInputChange}>
                        {registrationType.map((type: RegistrationType, index: number) => {
                            return <option key={index} value={type.id}>{type.name}</option>
                        })}
                    </select>
                    <button className="btn bg-blue-calm text-white mt-3" onClick={addInput}>
                        Přidat vstup
                    </button>
                </div>
            </div>
            <button className="btn btn-success col-md-3 my-5" onClick={editRegistration}>Upravit registraci</button>
        </form>
    </div>;
}